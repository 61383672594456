import React from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import FullscreenHero from 'components/FullscreenHero';
import { Section } from 'types';

type IndexPageTemplateProps = {
  intro: {
    title: string;
    subtitle: string;
    cta: {
      label: string;
      url: string;
    };
  };
  image: IGatsbyImageData;
  sections: Section[];
};

const IndexPageTemplate = ({ image }: IndexPageTemplateProps) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullscreenHero image={heroImage}>
        <div className="landing">
          <h1 className="landing__title">
            Profesjonalne usługi <br /> finansowe w Suwałkach
          </h1>
        </div>
      </FullscreenHero>
    </div>
  );
};

export default IndexPageTemplate;
